<template>
  <piepkaart :minLoadDataZoom="1">
    <template v-slot="{ bounds, minLoadDataZoom }">
      <piepkaart-wfs-layer
        :bounds="bounds"
        :minLoadDataZoom="minLoadDataZoom"
        wfs-url="https://portal.prvlimburg.nl/geodata/MILIEU/wfs"
        type-name="MILIEU:VOORMALIGE_STORTPLAATSEN_P"
        output-format="json"
        srs-name="EPSG:4326"
        :use-proxy="true"
        :use-bbox="false"
        :onEachFeature="onEachFeature"
        :pointToLayer="pointToLayer"
        :minRefetchDistance="10"
      ></piepkaart-wfs-layer>
    </template>
  </piepkaart>
</template>
<script>
import Piepkaart from "@/components/Piepkaart.vue";
import PiepkaartWfsLayer from "@/components/PiepkaartWfsLayer.vue";
import L from "leaflet";
export default {
  name: "Limburg",
  components: {
    Piepkaart,
    PiepkaartWfsLayer,
  },
  data() {
    return {
      maxBounds: L.latLngBounds([
        [50.76220193995123, 5.63796050257518],
        [51.75960725792513, 6.171548775153894],
      ]),
    };
  },
  methods: {
    pointToLayer(feature, latlng) {
      return L.circleMarker(latlng, {
        radius: 8,
        stroke: true,
        weight: 1,
        color: "#fff",
        fillColor: "#3B82F6",
        fillOpacity: 0.8,
      });
    },
    onEachFeature(feature, layer) {
      if (feature.properties) {
        layer.bindPopup(
          `
            <div>
              <div style="font-weight: 600">${feature.properties.LOCATIENAAM}</div>
              <div>Gemeente: ${feature.properties.GEMEENTE}</div>
            </div>
            `,
          {
            closeButton: false,
            autoPan: false,
          }
        );
      }
    },
  },
  created() {
    this.$store.commit("center", this.maxBounds.getCenter());
    this.$store.commit("zoom", 10);
  },
};
</script>
